import React, {useEffect, useState} from 'react';
import {Paginator} from "../../../../Infrastructure/services/Types/Paginator";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import ClickablePagination from "../../../Ui/ClickablePagination";
import AccountingHistorySkeleton from "./AccountingHistorySkeleton";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import XSelect from "../../../Ui/Select/XSelect";
import Input from "../../../Ui/Input/Input";
import {numberFormat} from "../../../../commons/numberHelper";


interface IRecord {
  id: number,
  name: string,
  createdAt: string,
  mainAmount: string,
  mashinno: string,
  vendor: string,
}

const AccountingHistory = () => {

  let [records, setRecords] = useState<any>(undefined)
  let {state} = useAppContext()
  let [page, setPage] = useState<number>(1)
  let [loaded, setLoaded] = useState<boolean>(false)
  let [filterSelected, setFilterSelected] = useState<any>({
    label: "همه موارد",
    value: 1
  })

  let [keyword, setKeyword] = useState<string>("")

  useEffect(() => {
    getData(1, keyword, filterSelected)
  }, []);


  let pageClicked = (page: any) => {
    setPage(page)
    getData(page, keyword, filterSelected)
  }


  let getData = (page: any, keyword: string, filter: number) => {
    setLoaded(false)
    webRequest({
      url: "/accounting/history",
      data: {
        page,
        keyword,
        filter
      },
      auth: state.auth,
    }).then(response => {
      try {
        validateResponse(response)
        setRecords(response.payload)
        setLoaded(true)
      } catch (e: any) {
      }
    })
  }

  let filterClicked = (value: any) => {
    setFilterSelected(value.value)
    getData(page, keyword, value.value)
  }

  let search = (keyword: string) => {
    getData(page, keyword, filterSelected)
  }

  let keywordChanged = (event: any) => {
    let _keyword;
    if (typeof event === "string") _keyword = event
    else _keyword = event.target.value;

    setKeyword(_keyword);
    search(_keyword)
  }


  let filters: any = [
    {label: "همه موارد", value: 1},
    {label: "تسویه شده", value: 2},
    {label: "تسویه نشده", value: 3},
  ]


  return (
    <>
      <div className={"card p-3"}>

        <div style={{marginBottom: "16px", display: "flex"}}>

          <div style={{width: "200px"}}>
            <span style={{color: "#222"}}>جست و جو</span>
            <Input label={"کلیدواژه"} value={keyword} setValue={keywordChanged} onEnter={keywordChanged}/>
          </div>
          &nbsp;&nbsp;

          <div style={{width: "200px"}}>
            <span style={{color: "#222"}}>فیلتر</span>
            <XSelect records={filters} optionSelected={filterClicked} defaultOption={filterSelected}/>
          </div>


        </div>

        {loaded && <div className={"table-responsive"}>

          <table className="table table-bordered tac">

            <thead>
            <tr>
              <td>شماره سفارش</td>
              <td>نام محصول</td>
              <td>تاریخ ثبت</td>
              <td>مبلغ فاکتور</td>
              <td>کارمزد ماشین نو</td>
              <td>فروشنده</td>
            </tr>
            </thead>

            <tbody>
            {records?.records.map((row: IRecord, index: number) => {
              return <tr key={index}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.createdAt}</td>
                <td>{row.mainAmount}</td>
                <td>{row.mashinno}</td>
                <td>{row.vendor}</td>
              </tr>
            })}
            </tbody>

          </table>
        </div>}


        {!loaded && <AccountingHistorySkeleton/>}
        <ClickablePagination lastPage={records?.lastPage} currentPage={page} total={records?.total} clicked={pageClicked}/>

      </div>
      <div className={"card p-3 mt-3"}>


        {loaded && <div className={"table-responsive"}>

          <table className="table table-bordered tac">

            <thead>
            <tr>
              <td>کل فروش</td>
              <td>تسویه شده</td>
              <td>تسویه نشده</td>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>{numberFormat(records?.done + records?.unDone)}</td>
              <td>{numberFormat(records?.done)}</td>
              <td>{numberFormat(records?.unDone)}</td>
            </tr>
            </tbody>

          </table>
        </div>}
      </div>
    </>
  );
};

export default AccountingHistory;