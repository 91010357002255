import React from 'react';
import Skeleton from "react-loading-skeleton";

const AccountingHistorySkeleton = () => {
  return (
    <div className={"table-responsive"}>
      <table className="table table-bordered tac">

        <thead>
        <tr>
          <td>شماره سفارش</td>
          <td>نام محصول</td>
          <td>تاریخ ثبت</td>
          <td>مبلغ فاکتور</td>
          <td>کارمزد ماشین نو</td>
          <td>فروشنده</td>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>
        <tr>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
          <td><Skeleton width="80%" height="30"/></td>
        </tr>

        </tbody>

      </table>

      <div className={"tac d-flex mt-2 justify-content-center"}>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>
        <Skeleton width="40px" height="40px" style={{marginLeft:"8px"}}/>

      </div>

    </div>
  );
};

export default AccountingHistorySkeleton;