import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import "./Label.css"
import Skeleton from "react-loading-skeleton";

interface IProp {
  id: number,
  state: string,
  city: string,
  address: string,
  phone: number,
  postalCode: string | null,
  receiver: string,
  showAddress: boolean,
}

const LabelPage = () => {

  let params = useParams()
  let {state} = useAppContext()

  let [response, setResponse] = useState<IProp | undefined>(undefined)

  useEffect(() => {
    webRequest({
      url: "/label",
      auth: state.auth,
      data: {
        id: params.id
      }
    }).then(response => setResponse(response.payload))
  }, [params.id]);


  return (

    <>
      {!response && <Skeleton width={"100%"} height={"400px"}/>}
      {response && <section className={"llllabel"}>
        <div className="box">
          <div className="row">
            <div className="col-1-3">
              <p style={{fontSize: 17, fontWeight: "bold"}}>شماره سفارش 73560</p>
            </div>
            <div className="col-1-3">
              <div
                className="col-1-2"
                style={{
                  marginTop: 0,
                  fontSize: 20,
                  fontWeight: "bold",
                  marginRight: 6
                }}
              >

              </div>
            </div>
            <div className="col-1-3" style={{textAlign: "left", paddingTop: 8}}>
              <img
                className="top-logo"
                src="https://mashinno.com/uploads/settings/settings-13-color.png?c=0"
                alt="فروشگاه آنلاین لوازم یدکی ماشین نو"
              />
            </div>
          </div>
        </div>
        <div className="box">
          <p
            style={{
              fontWeight: "bold",
              marginBottom: 0,
              marginTop: 4,
              fontSize: 17
            }}
          >
            فرستنده:ماشین نو &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; شماره فرستنده : 09120151169
          </p>
          <p style={{fontWeight: "bold", fontSize: 17}}>
            آدرس: - تهران ،خیابان جمهوری ، نرسیده به خیابان ملت ، کوچه مراغه ای ، بن
            بست یاس شرقی پلاک ۱۱ کد پستی: 1143954713 - شماره تماس: 02191301374
          </p>
        </div>
        <div className="box" style={{position: "relative"}}>

          <div className="row">
            <div className="col-3-4">
              <div className="row">
                <div className="col-1-2">
                  <p style={{fontSize: 17}}>گیرنده: {response.receiver}</p>
                  <p>کد پستی:
                    {response.postalCode}
                  </p>
                </div>
                <div className="col-1-2">
                  <p style={{fontSize: 17}}>استان:
                    {response.state}
                  </p>
                  <p style={{fontSize: 17}}>شهر: {response.city}</p>
                </div>
              </div>
              {response.showAddress && <>
                <div className="row">
                  <div className="col-1-2">
                    <p style={{marginTop: 0, fontWeight: "bold", fontSize: 17}}>
                      شماره تماس: {response.phone}
                    </p>
                  </div>
                  <div className="col-1-2" style={{marginTop: "-4px", marginRight: 6}}>
                  </div>
                </div>
                <div className="row" style={{position: "relative"}}>
                  <div className="">
                    <p
                      style={{
                        marginTop: 8,
                        marginBottom: 0,
                        fontSize: 17,
                        letterSpacing: 1,
                        lineHeight: 32
                      }}
                    >
                      آدرس:
                      {response.address}
                    </p>
                  </div>
                </div>
              </>}
            </div>
          </div>
        </div>
        <div className="box" style={{borderBottom: "1px solid #222"}}>
          <p
            style={{
              textAlign: "center",
              fontWeight: "bolder !important",
              fontSize: 17
            }}
          >
            لطفا در حضور مامور تحویل کالای دریافتی را بررسی نمایید.در غیر این صورت
            هرگونه مغایرت و شکستگی از سوی ماشین نو پذیرفته نخواهد شد
          </p>
        </div>
      </section>}
    </>
  );
};

export default LabelPage;